import AWS from "aws-sdk";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Buffer } from "buffer";
import "./css/TestCameraCapture.css";

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

interface Emotion {
  Type: string;
  Confidence: number;
}

interface Gender {
  Value: string;
  Confidence: number;
}

interface ImageWithDetails {
  image: string;
  emotions: Emotion[];
  gender: Gender;
}

const EmotionRecognition = () => {
  const [capturedImages, setCapturedImages] = useState<ImageWithDetails[]>([]);
  const [selectedImage, setSelectedImage] = useState<ImageWithDetails | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera:", err);
      }
    };
    getUserMedia();
  }, []);

  const detectEmotionsAndGender = useCallback(
    (
      imageBuffer: Buffer,
      callback: (emotions: Emotion[], gender: Gender) => void
    ) => {
      const rekognition = new AWS.Rekognition();
      const params = {
        Image: {
          Bytes: imageBuffer,
        },
        Attributes: ["ALL"],
      };
      rekognition.detectFaces(params, (err, data) => {
        if (err) {
          console.error("Error detecting emotions and gender:", err);
        } else {
          const emotions = data.FaceDetails?.[0]?.Emotions || [];
          const gender = data.FaceDetails?.[0]?.Gender || {
            Value: "Unknown",
            Confidence: 0,
          };
          callback(emotions as Emotion[], gender as Gender);
        }
      });
    },
    []
  );

  const formatEmotions = (emotions: Emotion[]) => {
    return emotions
      .map(
        (emotion) =>
          `${emotion.Type.toUpperCase()}: ${emotion.Confidence.toFixed(2)}%`
      )
      .join(", ");
  };

  const sendToBaserow = useCallback(
    async (
      dataUrl: string,
      timestamp: string,
      emotions: Emotion[],
      gender: Gender
    ) => {
      const baserowApiUrl =
        "https://baserow.tdzain.io/api/database/rows/table/638/?user_field_names=true";
      const baserowApiToken = "0d4SnLBKFIq3olklkRbnDMeyyLIVYqFS";
      const formattedEmotions = formatEmotions(emotions);
      const formattedGender = `${gender.Value.toUpperCase()}: ${gender.Confidence.toFixed(
        2
      )}%`;

      const requestData = {
        image_url: dataUrl,
        timestamp: timestamp,
        emotions: formattedEmotions,
        gender: formattedGender,
      };

      try {
        const response = await fetch(baserowApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${baserowApiToken}`,
          },
          body: JSON.stringify(requestData),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
      } catch (error) {
        console.error("Error sending data to Baserow:", error);
      }
    },
    []
  );

  const captureImage = useCallback(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async (blob) => {
          if (blob) {
            const arrayBuffer = await blob.arrayBuffer();
            const buffer = Buffer.from(arrayBuffer);

            const dataUrl = canvas.toDataURL("image/png");
            const timestamp = new Date().toISOString();

            detectEmotionsAndGender(
              buffer,
              (detectedEmotions, detectedGender) => {
                setCapturedImages((prevImages) => [
                  ...prevImages,
                  {
                    image: dataUrl,
                    emotions: detectedEmotions,
                    gender: detectedGender,
                  },
                ]);

                sendToBaserow(
                  dataUrl,
                  timestamp,
                  detectedEmotions,
                  detectedGender
                );
              }
            );
          }
        });
      }
    }
  }, [detectEmotionsAndGender, sendToBaserow]);

  const scrollRight = useCallback(() => {
    if (imageContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        imageContainerRef.current;
      const isAtEnd = scrollLeft + clientWidth >= scrollWidth - 1;

      if (isAtEnd) {
        imageContainerRef.current.scrollTo({ left: 0, behavior: "smooth" });
      } else {
        imageContainerRef.current.scrollBy({ left: 100, behavior: "smooth" });
      }
    }
  }, []);

  const scrollLeft = useCallback(() => {
    if (imageContainerRef.current) {
      const { scrollLeft } = imageContainerRef.current;
      const isAtStart = scrollLeft === 0;

      if (isAtStart) {
        imageContainerRef.current.scrollTo({
          left: imageContainerRef.current.scrollWidth,
          behavior: "smooth",
        });
      } else {
        imageContainerRef.current.scrollBy({ left: -100, behavior: "smooth" });
      }
    }
  }, []);

  const openModal = useCallback((image: ImageWithDetails) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedImage(null);
  }, []);

  return (
    <div className="container">
      <video ref={videoRef} autoPlay muted playsInline />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <div className="button-wrapper">
        <button onClick={captureImage}>Capture Image</button>
      </div>
      <div className="carousel-wrapper">
        {capturedImages.length >= 4 && (
          <button onClick={scrollLeft} className="carousel-button left">
            &lt;
          </button>
        )}
        <div className="image-container" ref={imageContainerRef}>
          {capturedImages.map((item, index) => (
            <div
              key={index}
              className="image-wrapper"
              onClick={() => openModal(item)}
            >
              <img
                src={item.image}
                alt={`Captured ${index}`}
                className="captured-image"
              />
              <div className="emotion-info">
                {item.emotions.map((emotion, emotionIndex) => (
                  <div key={emotionIndex}>
                    {emotion.Type}: {emotion.Confidence.toFixed(2)}%
                  </div>
                ))}
                <div>
                  GENDER: {item.gender.Value} (
                  {item.gender.Confidence.toFixed(2)}%)
                </div>
              </div>
            </div>
          ))}
        </div>
        {capturedImages.length >= 4 && (
          <button onClick={scrollRight} className="carousel-button right">
            &gt;
          </button>
        )}
      </div>

      {isModalOpen && selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-image-wrapper">
              <img
                src={selectedImage.image}
                alt="Selected"
                className="modal-image"
              />
            </div>
            <div className="modal-info-wrapper">
              {selectedImage.emotions.map((emotion, index) => (
                <div key={index}>
                  <strong>{emotion.Type}:</strong>{" "}
                  {emotion.Confidence.toFixed(2)}%
                </div>
              ))}
              <div>
                <strong>GENDER:</strong> {selectedImage.gender.Value} (
                {selectedImage.gender.Confidence.toFixed(2)}%)
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmotionRecognition;
